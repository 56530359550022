<template>
  <section class="container vld-parent">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#114C8E"
    ></loading>
    <b-row>
      <h2 class="text-primary">Edit Order Template</h2>
    </b-row>
    <b-row>
      <b-card style="width: 100%">
        <b-row>
          <b-col>
            <DeliveryDetails :ValidationModel="$v" />
            <!-- <b-dropdown-divider class="divider-margin"/> -->
            <ProductDetails />
            <!-- <b-dropdown-divider class="divider-margin"/> -->
            <SpecialInstructions />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button variant="primary" @click="updateTemplate()"
              >Save Template</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-row>
    <slideout-panel></slideout-panel>
  </section>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { mapState, mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import _ from "lodash";

import DeliveryDetails from "@/components/new-order-template/DeliveryDetails";
import ProductDetails from "@/components/new-order-template/ProductDetails";
import SpecialInstructions from "@/components/new-order-template/SpecialInstructions";
import { canViewUsers } from "@/shared/utils/permissions";

export default {
  name: "EditTemplate.vue",

  components: {
    Loading,
    DeliveryDetails,
    ProductDetails,
    SpecialInstructions,
  },

  data() {
    return {
      isLoading: false,
      errorText: "",
    };
  },

  beforeCreate() {
    console.log("enter");
    try {
      var requestData = {
        id: this.$route.params.id,
        jwtToken: this.$store.state.user.jwtToken,
      };

      this.$store.dispatch(
        "newOrderTemplate/setSelectedOrderTemplateData",
        requestData
      );
    } catch (error) {
      var errorMessage = handleRequestError(error);
      this.$snotify.error(errorMessage, "Error");
    }
  },

  async created() {
    this.$root.$on("isEditLoading", (val) => {
      this.isLoading = val;
    });

    this.isLoading = true;
    try {
      //Load All BillTos
      await this.$store.dispatch(
        "newOrderTemplate/getAllBillTos",
        this.$store.state.user.jwtToken
      );

      //Load All Consignees
      if (this.$store.state.newOrderTemplate.billTos.length > 0) {
        await this.$store.dispatch("newOrderTemplate/getConsignees", {
          jwtToken: this.$store.state.user.jwtToken,
          billTo: this.$store.state.newOrderTemplate.billTos[0].id,
        });
      }

      //Load Shippers and Commodities
      if (this.$store.state.newOrderTemplate.selectedConsignee) {
        await this.$store.dispatch("newOrderTemplate/getShippers", {
          jwtToken: this.$store.state.user.jwtToken,
          billTo: this.$store.state.newOrderTemplate.billTos[0].id,
          consignee: this.selectedConsignee.id,
        });

        await this.$store.dispatch("newOrderTemplate/getCommodities", {
          jwtToken: this.$store.state.user.jwtToken,
          billTo: this.$store.state.newOrderTemplate.billTos[0].id,
          consignee: this.selectedConsignee.id,
        });
      }
    } catch (error) {
      var errorMessage = handleRequestError(error);
      this.$snotify.error(errorMessage, "Error");
    }

    this.isLoading = false;
  },

  methods: {
    async updateTemplate() {
      let errorMessage = "";
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.isLoading = true;
          var billToId = this.selectedProducts[0].deliverySite.billTo;

          var selectedBillTo = _.find(this.billTos, { id: billToId });

          var objBillTo = {
            id: selectedBillTo.id,
            name: selectedBillTo.name,
          };

          var details = [];

          this.selectedProducts.forEach((stop) => {
            details.push({
              deliverySite: {
                ...stop.deliverySite,
                address1: stop.deliverySite.address,
              },
              shipper: stop.shipper,
              supplier: stop.supplier,
              accountOf: stop.accountOf,
              amount: stop.amount,
              product: stop.product,
              notes: "",
              components: [],
              amountUnitOfMeasure: stop.amountUnitOfMeasure, //"Gallon"
            });
          });

          let selectedUsers = this.canViewUsers
            ? this.getFilteredSelectedUsersUIds
            : this.$store.state.newOrderTemplate.selectedUsersId;
          var objOrderTemplate = {
            templateId: this.$store.state.newOrderTemplate.templateId,
            templateName: this.templateName,
            receiver: "TMWCLT",
            sender: "TRPFCS",
            division: this.getDivisionForTemplate,
            billTo: objBillTo,
            po: this.poNumber ? this.poNumber.trim() : null,
            notes: this.notes,
            details: details,
            additionalReferenceNumber: "",
            amountUnitOfMeasure: "",
            isMultiUser: this.isMultiUser,
            isAssignToAllUsers: this.isAssignToAllUsers,
            selectedUsers: selectedUsers,
          };

          var requestData = {
            uId: this.$route.params.id,
            orderTemplate: objOrderTemplate,
            jwtToken: this.$store.state.user.jwtToken,
          };

          await this.$store.dispatch(
            "newOrderTemplate/updateOrderTemplate",
            requestData
          );

          this.$router.push({ name: "order-templates" });
        } else {
          if (
            this.$v.selectedProducts.$error &&
            this.$v.selectedProducts.minLength
          ) {
            errorMessage = "You must add at least one product! ";
          }
        }
      } catch (error) {
        errorMessage = handleRequestError(error);
      }
      if (errorMessage) {
        this.$snotify.error(errorMessage, "Error");
      }
      this.isLoading = false;
    },

    displayError(title, message) {
      this.$snotify.error(message, title);
    },
  },

  validations() {
    return {
      templateName: {
        required,
      },
      selectedProducts: {
        required,
        minLength: minLength(1),
      },
    };
  },

  computed: {
    ...mapState("newOrderTemplate", [
      "templateName",
      "accountOfs",
      "billTos",
      "shippers",
      "suppliers",
      "commodities",
      "consignees",
      "selectedConsignee",
      "selectedDeliveryDate",
      "selectedDeliveryTime",
      "selectedFromCustomTime",
      "selectedToCustomTime",
      "poNumber",
      "notes",
      "selectedProducts",
      "isMultiUser",
      "isAssignToAllUsers",
    ]),

    ...mapGetters("newOrderTemplate", [
      "getFilteredSelectedUsersUIds",
      "getDivisionForTemplate",
    ]),

    canViewUsers() {
      return canViewUsers(this.$store);
    },
  },
};
</script>

<style scoped>
</style>
